import React from 'react';
import texts from './texts.json';
import setDefaultDynamicTexts from 'shared/ui/helpers/locales/setDefaultDynamicTexts';

export default Object.freeze(
  setDefaultDynamicTexts(texts, {
    tour: {
      progress: ({current, total}) => `Tip ${current} of ${total}`
    },
    dragToSort: {
      aria: {
        grabbed: ({initial} = {}) => `Grabbed item from position ${initial}. Move it by pressing up/down arrows`,
        moved: ({initial, position} = {}) =>
          `About to move the item from position ${initial} to position ${position}. Drop it by pressing enter. Cancel the action by pressing escape`,
        dropped: ({position} = {}) => `Dropped. The item placed to position ${position}`
      }
    },
    cookieConsent: {
      text: ({companyName}) =>
        `${companyName} uses cookies to improve your experience on our site. Before you continue, let us know if you’re happy to accept the use of cookies, in accordance with our`,
      textSettings: ({companyName, cookiesPolicy}) => (
        <span>
          {companyName} uses cookies to improve user’s experience, personalise ads and analyse traffic. You can accept
          all cookies, decline all optional cookies, or manage your cookie settings. To learn more, view our{' '}
          {cookiesPolicy}.
        </span>
      ),
      sections: {
        about: {
          description: ({cookiesPolicy}) => (
            <span>
              This website utilizes cookies to store information on your computer. Cookies are small text files that can
              be used by websites to make a user’s experience more efficient. You can view our {cookiesPolicy} as well
              as customize your cookies settings:
            </span>
          )
        }
      }
    },
    rating: {
      buttonLabelWithRating: rating => `Set rating - ${rating} selected`,
      rateLabel: rating => `Rate ${rating}`,
      ratedLabel: rating => `Already rated: ${rating} (click to remove rating)`
    },
    carousel: {
      pagination: {
        bulletLabel: index => `Go to page ${index}`,
        currentPageLabel: index => `Current page, page ${index}`
      }
    },
    datePickerFilter: {
      presetRange: (startDate, endDate) => `${startDate} to ${endDate}`
    },
    table: {
      pagination: {
        status: {
          label: ({firstVisibleRow, lastVisibleRow, totalRows}) =>
            `Showing ${firstVisibleRow} to ${lastVisibleRow} of ${totalRows} entries`
        }
      },
      dragToReorder: {
        aria: {
          grabbed: ({initial} = {}) => `Grabbed column from position ${initial}. Move it by pressing up/down arrows`,
          moved: ({initial, position} = {}) =>
            `About to move the column from position ${initial} to position ${position}. Drop it by pressing enter. Cancel the action by pressing escape`,
          dropped: ({position} = {}) => `Dropped. The column placed to position ${position}`
        }
      },
      cell: {
        checkbox: {
          label: rowIndex => `Select row ${rowIndex}`
        },
        header: {
          resizer: {
            value: width => `${width} pixels`
          }
        }
      }
    }
  })
);
